<template>
  <div class="page">
    <div class="relative bg-white contents">
      <img class="box_bg"
           src="../../assets/group_bg.png">
      <div class="box">
        <div class="flex">
          <img :src="robotInfo.headImgUrl"
               class="avatar">
          <div class="ml-1 lp_1">
            <div class="fs-18 b">{{ robotInfo.nickName }}</div>
            <div class="mt2">
	            已托管群数：{{ additionalVal.totalUserOpen }}/ {{ additionalVal.totalUserOpen > 6 ? 10 : 6 }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <van-search v-model="likeName"
                    placeholder="搜索"
                    shape="round"
                    class="flex-1"
                    @search="getData()" />
        <van-button type="primary"
                    round
                    size="small"
                    class="mr-3"
                    style="padding: 4px 13px"
                    @click="getData()">搜索</van-button>
        <van-button type="primary"
                    plain
                    round
                    size="small"
                    class="mr-3"
                    style="padding: 4px 13px"
                    @click="syncGroup()">刷新</van-button>
      </div>
      <van-divider class="mt-1" />
      <ul class="content">
        <li v-for="(el, index) in groupList"
            :key="el.chatroomId"
            class="mb-5 ml-3">
          <!-- <van-checkbox :disabled="el.openState=='2'"
                        :checked="checkValue.has(el.chatroomId)"
                        shape="square"
                        @click="el.openState!='2' ? onChange(el) : ''">
            <div class="text-flow"
                 v-if="el.openState=='2'">{{ index + 1 }}、{{ el.groupName }}（已开通）</div>
            <div class="text-flow"
                 v-else-if="el.openState=='2'">{{ index + 1 }}、{{ el.groupName }}（此群为采集群）</div>
            <div class="text-flow color-info"
                 v-else>{{ index + 1 }}、{{ el.groupName ? el.groupName : '未命名' }}</div>
          </van-checkbox> -->
          <!--isCollectChatroom 是否采集群-->
          <van-checkbox :disabled="el.openState=='2' || el.isCollectChatroom"
                        :checked="checkValue.has(el.chatroomId)"
                        @click="(el.openState!='2' &&  !el.isCollectChatroom) ? onChange(el) : ''">
            <div class="text-flow color-info"
                 v-if="el.isCollectChatroom">{{ index + 1 }}、{{ el.groupName || '未命名' }}（采集群不能跟发）</div>
            <div class="text-flow color-info"
                 v-else-if="el.openState=='2'">{{ index + 1 }}、{{ el.groupName || '未命名'}}（已开通{{el.openChatroomRobotName}}）</div>
            <div class="text-flow"
                 v-else>{{ index + 1 }}、{{ el.groupName ? el.groupName : '未命名' }}</div>
          </van-checkbox>
        </li>
      </ul>
    </div>

    <div class="fixed_bottom flex">
      <div class="color-gray">已选群聊：</div>
      <div class="color-danger">{{ [...checkValue].length }}</div>
      <button class="btn flex flex-right h-center"
              @click="handleOpenGroup()">立即托管</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Toast } from 'vant'

export default {
  name: 'Index',
  components: {},
  data () {
    return {
      loading: false,
      robot: {
        nickName: '宫姣栋',
        headImgUrl: '',
        online: 2,
        openNum: 0,
        allNum: 30
      },
      pageIndex: 1,
      pageSize: 1000,
      likeName: '',
      checkValue: new Map(),
      checked: true,
      result: ['a', 'b'],
      groupList: [
      ],
      // 开群状态,1等待开通,2已经开通
      openState: 0,
	    additionalVal: {
		    totalUserOpen: 0
	    }    }
  },
  created () {
    const _this = this
    _this.getData()
  },
  computed: {
    ...mapGetters(['robotInfo'])
  },
  methods: {
    getData () {
      const _this = this
      _this.$post('/WxRobot/QueryChatrooms', {
        pageIndex: _this.pageIndex,
        pageSize: _this.pageSize,
        sortName: '',
        sortOrder: 0,
        robotWxIds: [_this.robotInfo.robotWxId],
        likeName: _this.likeName
      })
        .then((res) => {
          _this.groupList = res.dataList
          _this.additionalVal = res.additionalVal
        })
    },
    async syncGroup () {
      const _this = this
      await _this.$get('/Common/SyncRobotChatRoom', {
        robotWxId: _this.robotInfo.robotWxId
      }).then((res) => {
        _this.$Toast('刷新成功')
      })
      _this.getData()
    },
    onChange (item) {
      const _this = this
      if (_this.checkValue.has(item.chatroomId)) {
        _this.checkValue.delete(item.chatroomId)
      } else {
        _this.checkValue.set(item.chatroomId, true)
      }
    },
    async handleOpenGroup () {
      const _this = this
      const robotWxId = _this.robotInfo.robotWxId
      const group = []
      for (const key of _this.checkValue.keys()) {
        group.push({ chatroomId: key, robotWxId: robotWxId })
      }
      if (group.length === 0) {
        return _this.$Toast('请先选择群')
      }
      const toast1 = Toast.loading({
        message: '开群中...',
        forbidClick: true,
        loadingType: 'spinner'
      })
      await _this.$post('/WxRobot/OpenChatrooms', group).then((res) => {
        _this.$Toast('开群成功')
      })
      toast1.clear()
      _this.getData()
      _this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
@import "openGroup";
</style>
